import React, { useState } from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import produce from "immer"
import LayoutCourse from "../../../../../layouts/course"

import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Select from "../../../../../components/select"
import Stack from "../../../../../components/stack"
import TextPlaceholder from "../../../../../components/text-placeholder"

const Page = () => {
  const { setAnswer, getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const taskProps = {
    courseId: "jahreszeiten",
    chapterId: "02-eigenes-gedicht",
    taskId: "silben",
  }
  const answer = getAnswer({ ...taskProps, taskId: "form-finden" })
  const verses = answer?.verses || 2
  const lines = answer?.lines || 4

  const [words, setWords] = useState(
    Array(verses)
      .fill()
      .map(() => Array(lines).fill(4))
  )

  const onUpdateWord = ({ value, verse, line }) => {
    const newState = produce(words, (draft) => {
      draft[verse][line] = value
    })
    setWords(newState)
  }

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        setAnswer({
          ...taskProps,
          answer: {
            verses,
            lines,
            words,
          },
        })
        navigate("/kurse/jahreszeiten/02-eigenes-gedicht/versteckte-ordnung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Silben" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Silben
          </Heading>
        </Stack>
        <Note variant="task">
          Lege nun fest, wie viele Silben in einer Zeile stehen dürfen.
        </Note>
        <TextPlaceholder paragraphs={verses} lines={lines} words={words}>
          {({ paragraph, line, lineContent }) => (
            <Columns alignY="center">
              <Column>{lineContent}</Column>
              <Column width={24}>
                <Select
                  id={`words[${paragraph}][${line}]`}
                  name={`words[${paragraph}][${line}]`}
                  value={words[paragraph][line]}
                  onChange={(event) => {
                    onUpdateWord({
                      value: parseInt(event.target.value),
                      verse: paragraph,
                      line,
                    })
                  }}
                >
                  {Array(12)
                    .fill()
                    .map((_, i) => (
                      <option key={`option-${i}`} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                </Select>
              </Column>
            </Columns>
          )}
        </TextPlaceholder>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
